import { useGetAlgoliaSearchIndex, useSiteStoreCode } from '#imports'
import type { Prescription } from '@/types/prescription'

export const useLensInfoApi = () => {
  const { isEyeBotSiteStore } = useSiteStoreCode()
  const search = (prescription: Prescription, params: any) => {
    const algoliaIndex = useGetAlgoliaSearchIndex()
    return $fetch<any>(`/api/get-lens-info`, {
      query: {
        prescription: isEyeBotSiteStore.value ? undefined : prescription,
        algoliaQueryParams: params,
        algoliaIndex,
        prescriptionType: prescription.lensType,
      },
    })
  }
  const getLensPrescriptionRange = (
    lensIndex: string,
    prescription: Prescription,
  ) => {
    const algoliaIndex = useGetAlgoliaSearchIndex()
    return $fetch<any>(`/api/get-lens-prescription-range`, {
      query: {
        prescription: isEyeBotSiteStore.value ? undefined : prescription,
        lensIndex,
        algoliaIndex,
        prescriptionType: prescription.lensType,
      },
    })
  }
  return { search, getLensPrescriptionRange }
}
